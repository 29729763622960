// Imports
// ------------
import React from 'react';
import Button from '@parts/Button';
import { Row, Column } from '@waffl';

// Styles
// ------------
import { Jacket, Content, Text } from './styles';

// Component
// ------------
const CareersIntro = ({ text }) => (
    <Jacket pad id="intro">
        <Row isExpanded>
            <Column small={12} medium={10} pushMedium={1} xlarge={8} pushXlarge={2} mpad>
                <Content>
                    <Text>{text}</Text>
                    <Button icon="jobs" to="#availabilities" label="Availabilties" />
                </Content>
            </Column>
        </Row>
    </Jacket>
);

export default CareersIntro;