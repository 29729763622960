// Imports
// ------------
import React, { useState } from 'react';
import { Row, Column } from '@waffl';
import ReactPlayer from 'react-player/file';
import { StructuredText } from 'react-datocms';

// Video
import vid from '@images/careers.mp4';

// Styles
// ------------
import { Jacket, Video, Content, Heading, SubHeading, Text, Process, Important } from './styles';

// Component
// ------------
const CareersInterviewProcess = ({ title, process }) => {
    // NOTE • State
    const [ready, setReady] = useState(false);

    // NOTE • Functions
    const handleReady = () => {
        setReady(true);
    };

    return (
        <Jacket>
            <Video>
                <ReactPlayer
                    url={vid}
                    onReady={handleReady}
                    playing={ready}
                    loop
                    muted
                    playsinline
                    width={`100%`}
                    height={`100%`}
                    controls={false}
                />
            </Video>

            <Content pad>
                <Row isExpanded>
                    <Column small={12} large={7} pushLarge={1} mpad>
                        <Heading>{title}</Heading>
                    </Column>
                </Row>

                {process.map(({ title, desc, importantText, importantTextNeeded }) => (
                    <Process key={title} padBottom>
                        <Row isExpanded isEqual>
                            <Column small={12} large={3} pushLarge={1} mpad>
                                <SubHeading>{title}</SubHeading>
                            </Column>

                            <Column small={12} large={7} pushLarge={1} mpad>
                                <Text>
                                    <StructuredText data={desc} />
                                    {importantTextNeeded && (<Important>* {importantText}</Important>)}
                                </Text>
                            </Column>
                        </Row>
                    </Process>
                ))}
            </Content>
        </Jacket>
    );
}

export default CareersInterviewProcess;