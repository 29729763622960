// Imports
// ------------
import React, { useState } from 'react';
import { Row, Column } from '@waffl';
import ReactPlayer from 'react-player/file';
import SingleCareer from './SingleCareer';

// Video
import vid from '@images/careers-availabilities.mp4';

// Styles
// ------------
import { Jacket, Video, Content, Heading, Jobs } from './styles';

// Component
// ------------
const CareersAvalabilities = ({ jobs }) => {
    // NOTE • State
    const [ready, setReady] = useState(false);

    // NOTE • Functions
    const handleReady = () => {
        setReady(true);
    };

    return (
        <Jacket id="availabilities">
            <Video>
                <ReactPlayer
                    url={vid}
                    onReady={handleReady}
                    playing={ready}
                    loop
                    muted
                    playsinline
                    width={`100%`}
                    height={`100%`}
                    controls={false}
                />
            </Video>

            <Content pad>
                <Row isExpanded>
                    <Column small={12} large={4} pushLarge={1} mpad>
                        <Heading>Current Openings</Heading>
                    </Column>
                </Row>

                <Row isExpanded>
                    <Column small={12} large={10} pushLarge={1} mpad>
                        <Jobs>
                            {jobs.map(({ name, jobType, jobUrl, salary }, i) => (
                                <SingleCareer
                                    key={i}
                                    name={name}
                                    jobType={jobType}
                                    jobUrl={jobUrl}
                                    salary={salary}
                                />
                            ))}
                        </Jobs>
                    </Column>
                </Row>
            </Content>
        </Jacket>
    );
}

export default CareersAvalabilities;
