// Imports
// ------------
import React from 'react';
import SingleBenefit from './SingleBenefit';
import { Row, Column } from '@waffl';

// Styles
// ------------
import { Jacket, Title, BenefitGrid } from './styles';

// Component
// ------------
const CareersIntro = ({ title, benefits }) => (
    <Jacket pad>
        <Row isExpanded>
            <Column small={12} large={5} pushLarge={1} mpad>
                <Title>{title}</Title>
            </Column>
        </Row>

        <Row isExpanded>
            <Column small={12} large={10} pushLarge={1} mpad>
                <BenefitGrid>
                    {benefits.map(({ id, title, desc }, i) => (
                        <SingleBenefit
                            key={id}
                            number={i + 1}
                            title={title}
                            desc={desc}
                        />
                    ))}
                </BenefitGrid>
            </Column>
        </Row>
    </Jacket>
);

export default CareersIntro;