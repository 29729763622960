// Imports
// ------------
import React from 'react';

// Styles
// ------------
import { Jacket, Number, Heading, Text } from './styles';

// Component
// ------------
const SingleBenefit = ({ number, title, desc }) => {

    // NOTE • Function to add leading zero's
    const zeroPad = (num, places) => {
        let zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join("0") + num;
    }

    return (
        <Jacket>
            <Number>{zeroPad(number, 2)}</Number>
            <Heading>{title}</Heading>
            <Text>{desc}</Text>
        </Jacket>
    );
}

export default React.memo(SingleBenefit);