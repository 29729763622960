// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, Div, H3 } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: relative;
    z-index: 14;
    background: ${props.theme.colors.brand.bc1};
`);

export const Video = styled.div(props => css`
    position: sticky;
    z-index: 1;
    top: 0; left: 0;
    width: 100%;
    height: calc(var(--vhs, 1vh) * 120);

    ${breakup.large`
        height: 100vh;
    `}

    video {
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: -24rem center;

        ${breakup.large`
            object-position: center center;
        `}
    }
`);

export const Content = styled(Div)(props => css`
    position: relative;
    z-index: 2;

    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0; left: 0; right: 0; bottom: -2px;

        background: linear-gradient(180deg,
            ${props.theme.colors.brand.bc1trans0} 0%,
            ${props.theme.colors.brand.bc1} 90%
        );
    }
`);

export const Heading = styled(H3)(props => css`
    position: relative;
    z-index: 2;
    margin: 0 0 ${props.theme.space.small};

    ${breakup.large`
        margin: 0 0 50vh;
    `}
`);


export const Jobs = styled(Div)(props => css`
    position: relative;
    z-index: 2;

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3.6rem;
    width: 100%;

    ${breakup.large`
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 6rem 3.6rem;
    `}
`);