// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, Div, H2 } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: relative;
    z-index: 14;
    ${props.theme.vh}
    max-height: 36rem;

    ${breakup.medium`
        max-height: 96rem;
    `}

    ${breakup.large`
        ${props.theme.vh}
        max-height: 90rem;
    `}
`);


export const Background = styled(Section)(props => css`
    position: fixed;
    left: 0; right: 0; bottom: 0;
    ${props.theme.vh}
    z-index: 9;
    background: ${props.theme.colors.brand.bc1};

    transform: scale(2);
    transform-origin: top center;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        mix-blend-mode: lighten;
    }
`);

export const Content = styled(Div)(props => css`
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    width: 100%;
    height: 100%;
`);

export const Heading = styled(H2)(props => css`
    color: ${props.theme.colors.brand.bc5};
    

    ${breakup.large`
        width: 70%;
    `}
`);

export const ButtonPos = styled.div(props => css`
    /* align-self: flex-end; */

    ${breakup.large`
        align-self: flex-end;
    `}
`);