// Imports
// ------------
import React from 'react';
import Hero from '@parts/Hero';
import CareersIntro from '@parts/Careers/CareersIntro';
import CareersAbout from '@parts/Careers/CareersAbout';
import CareersTestimonial from '@parts/Careers/CareersTestimonial';
import CareersBenefits from '@parts/Careers/CareersBenefits';
import CareersInterviewProcess from '@parts/Careers/CareersInterviewProcess';
import CareersAvailabilities from '@parts/Careers/CareersAvailabilities';
import CallToAction from '@parts/CallToAction';
import Footer from '@parts/Footer';
import Seo from '@parts/Seo';
import { graphql } from 'gatsby';



// Component
// ------------
const CareersPage = ({ data }) => {
    const dato = data.page;

    return (
        <>
            <Seo
                title={data.seo.seoMeta.title}
                desc={data.seo.seoMeta.desc}
            />

            <Hero
                title={dato.heroTitle}
                excerpt={dato.heroSubtext}
            />

            <CareersIntro
                text={dato.introText}
            />

            <CareersAbout
                heading={dato.aboutHeading}
                text={dato.aboutText}
                image={dato.aboutImage}
            />

            <CareersTestimonial
                author={dato.testimonialOneAuthor}
                quote={dato.testimonialOneQuote}
            />


            <CareersBenefits
                title={dato.benefitsTitle}
                benefits={dato.benefits}
            />

            <CareersTestimonial
                author={dato.testimonialTwoAuthor}
                quote={dato.testimonialTwoQuote}
            />

            <CareersInterviewProcess
                title={dato.interviewProcessTitle}
                process={dato.interviewProcess}
            />

            <CareersTestimonial
                author={dato.testimonialThreeAuthor}
                quote={dato.testimonialThreeQuote}
            />

            {dato.jobs.length >= 1 && (
                <CareersAvailabilities
                    jobs={dato.jobs}
                />
            )}


            <CallToAction
                title={data.cta.title}
            />

            <Footer />
        </>
    );
}

export default CareersPage;

// GraphQL
// ------------
export const query = graphql`
	query CareersPageQuery {
		# NOTE • SEO
		seo: datoCmsCareersPage {
			seoMeta {
				title
				desc: description
			}
		}

		# NOTE • Call To Action
		cta: datoCmsCallToAction {
			title
		}


        # NOTE • Page Specific
        page: datoCmsCareersPage {
            # Hero
            heroTitle
            heroSubtext

            # Intro
            introText

            # About
            aboutHeading
            aboutText
            aboutImage {
                alt
                data: gatsbyImageData(
                    width: 1440
                    height: 900
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                )
            }

            # Testimonials
            testimonialOneAuthor {
                id
                firstName: name
                lastName
                jobTitle: authorJobTitle
                pic: authorPicture {
                    alt
                    data: gatsbyImageData(
                        width: 84
                        height: 84
                        placeholder: BLURRED
                        layout: FIXED
                    )
                }
            }
            testimonialOneQuote
            testimonialTwoAuthor {
                id
                firstName: name
                lastName
                jobTitle: authorJobTitle
                pic: authorPicture {
                    alt
                    data: gatsbyImageData(
                        width: 84
                        height: 84
                        placeholder: BLURRED
                        layout: FIXED
                    )
                }
            }
            testimonialTwoQuote
            testimonialThreeAuthor {
                id
                firstName: name
                lastName
                jobTitle: authorJobTitle
                pic: authorPicture {
                    alt
                    data: gatsbyImageData(
                        width: 84
                        height: 84
                        placeholder: BLURRED
                        layout: FIXED
                    )
                }
            }
            testimonialThreeQuote

            # Benefits
            benefitsTitle
            benefits {
                id: originalId
                title: benefitTitle
                desc: benefitDescription
            }

            # Interview Process
            interviewProcessTitle
            interviewProcess {
                title: processTitle
                desc: processDescription {
                    value
                }
                importantTextNeeded
                importantText
            }

            # Availabiltiies
            jobs: availabilities {
                name
                jobType
                jobUrl
                salary
            }
        }
	}
`
