// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Span, P, H6 } from '@tackl';

// Exports
// ------------
export const Jacket = styled.div(props => css`
    position: relative;
`);


export const Number = styled(Span)(props => css`
    position: relative;
    color: ${props.theme.colors.brand.bc5o4};
    margin: 0 0 2.4rem;

    &:after {
        content: "";
        position: absolute;
        top: 50%; left: 4.8rem; right: 0;
        transform: translateY(-50%);

        height: 1px;
        background: ${props.theme.colors.brand.bc5};
        opacity: 0.1;
    }
`);

export const Heading = styled(H6)(props => css`
    margin: 0 0 2.4rem;

    ${breakup.large`
        margin: 0 0 3.6rem;
    `}
`);

export const Text = styled(P)(props => css`

`);