// Imports
// ------------
import React from 'react';
import Icon from '@icons';

// Styles
// ------------
import { Jacket } from './styles';

// Component
// ------------
const Button = ({ icon, label, to }) => (
    <Jacket to={to} className="button">
        {icon && <Icon type={icon} />}
        <span data-text={label}>{label}</span>
    </Jacket>
);

export default React.memo(Button);