// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, Div } from '@tackl';
import { h2Styles, h4Styles } from '@tackl-type';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: relative;
    z-index: 14;
    background: ${props.theme.colors.brand.bc1};
`);


export const Content = styled(Div)(props => css`
    ${breakup.large`
        text-align: center;
    `}
`);

export const Text = styled.h2(props => css`
    ${h2Styles}
    margin-bottom: 2.4rem;


    ${breakup.large`
        ${h4Styles}

        text-align:  center;
        margin-bottom: 6rem;
    `}
`);