// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, Div, H3, P, ContentBlock, Span } from '@tackl';
import { h5Styles } from '@tackl-type';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: relative;
    z-index: 14;
    background: ${props.theme.colors.brand.bc1};
`);

export const Video = styled.div(props => css`
    position: sticky;
    z-index: 1;
    top: 0; left: 0;
    width: 100%;
    height: ${props.theme.vh};

    ${breakup.large`
        height: 100vh;
    `}

    video {
        object-fit: cover;
    }
`);

export const Content = styled(Div)(props => css`
    position: relative;
    z-index: 2;

    background: linear-gradient(180deg,
        ${props.theme.colors.brand.bc1trans0} 0%,
        ${props.theme.colors.brand.bc1trans90} 30%
    );
`);

export const Heading = styled(H3)(props => css`
    margin: 0 0 ${props.theme.space.small};

    ${breakup.large`
        margin: 0 0 50vh;
    `}
`);

export const SubHeading = styled(P)(props => css`
    position: sticky;
    top: ${props.theme.space.large};
    left: 0;
    color: ${props.theme.colors.brand.bc5};
    margin: 0 0 2.4rem;
`);

export const Text = styled(ContentBlock)(props => css`
    p {
        ${breakup.large`
            ${h5Styles}
            color: ${props.theme.colors.brand.bc5};
        `}
    }
`);

export const Process = styled(Div)(props => css`

`);

export const Important = styled(Span)(props => css`
    color: ${props.theme.colors.brand.bc5o6};
`);