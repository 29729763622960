// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, Div, Span, Em } from '@tackl';
import { h3Styles, h5Styles } from '@tackl-type';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: relative;
    z-index: 14;
    min-height: 60rem;
    background: linear-gradient(
        90deg,
        ${props.theme.colors.brand.bc2} 0%,
        ${props.theme.colors.brand.bc3} 100%
    );
`);

export const Content = styled(Div)(props => css`
    border: 1px solid ${props.theme.colors.brand.bc5o2};
    border-left: none;
    border-right: none;

    padding: 2.4rem 0;

    ${breakup.large`
        padding: 3.6rem 0;
    `}
`);

export const Title = styled(Span)(props => css`
    margin: 0 0 ${props.theme.space.small};

    ${breakup.medium`
        margin: 0 0 ${props.theme.space.medium};
    `}

    ${breakup.large`
        margin: 0 0 ${props.theme.space.large};
    `}
`);

export const Quote = styled.blockquote(props => css`
    ${h3Styles}

    margin: 0 0 ${props.theme.space.small};

    ${breakup.medium`
        margin: 0 0 ${props.theme.space.medium};
    `}

    ${breakup.large`
        ${h5Styles}
        margin: 0 0 ${props.theme.space.large};
    `}
`);

export const Author = styled(Div)(props => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2.4rem;
`);

export const AuthorPic = styled.picture(props => css`
    width: 6rem;
    height: 6rem;
    border-radius: 100%;
    overflow: hidden;

    ${breakup.large`
        width: 8.4rem;
        height: 8.4rem;
    `}

    .gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
    }
`);

export const AuthorDetails = styled(Div)(props => css`

`);

export const AuthorName = styled(Span)(props => css`
    color: ${props.theme.colors.brand.bc5};
`);

export const AuthorRole = styled(Em)(props => css`
    color: ${props.theme.colors.brand.bc5o6};
`);
