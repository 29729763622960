// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, Div, P } from '@tackl';
import { pStyles, h3Styles, h4Styles } from '@tackl-type';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: relative;
    z-index: 14;
    background: ${props.theme.colors.brand.bc1};

    padding-bottom: ${props.theme.space.small};

    ${breakup.medium`
        padding-bottom: ${props.theme.space.medium};
    `}

    ${breakup.large`
        padding-bottom: ${props.theme.space.large};
    `}
`);

export const Picture = styled.div(props => css`
    position: relative;
    padding-top: 62.5%;
    overflow: hidden;
    width: 100%;

    margin-bottom: ${props.theme.space.small};

    ${breakup.medium`
        margin-bottom: ${props.theme.space.medium};
    `}

    ${breakup.large`
        margin-bottom: ${props.theme.space.large};
    `}
`);

export const Parallax = styled.picture(props => css`
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;

    transform: translateY(6%) scale(1.12);


    .gatsby-image-wrapper {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
    }
`);

export const Sticky = styled.div(props => css`
    height: 100%;
`);

export const StickyTitle = styled.h2(props => css`
    ${pStyles}
    color: ${props.theme.colors.brand.bc5o4};
    margin: 0 0 2.4rem;

    ${breakup.large`
        position: sticky;
        top: ${props.theme.space.large};
        left: 0;
        margin: 0;
        color: ${props.theme.colors.brand.bc5};
    `}
`);


export const Content = styled(Div)(props => css`
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    ${breakup.large`
        gap: 6rem;
    `}
`);

export const Heading = styled.h3(props => css`
    ${h3Styles}

    ${breakup.large`
        ${h4Styles}
    `}
`);

export const Text = styled(P)(props => css`

`);
