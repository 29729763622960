// Imports
// ------------
import React from 'react';
import { Row, Column } from '@waffl';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------------
import {
    Jacket,
    Content,
    Title,
    Quote,
    Author,
    AuthorPic,
    AuthorDetails,
    AuthorName,
    AuthorRole
} from './styles';

// Component
// ------------
const CareersTestimonial = ({ author, quote }) => {

    // NOTE • Alt Backup
    const altText = author.pic.alt ? author.pic.alt : `Sirio Strategies • Quote from: ${author.firstName + " " + author.lastName}`;

    return (
        <Jacket pad>
            <Row isExpanded>
                <Column small={12} large={10} pushLarge={1} mpad>
                    <Content>
                        <Title>Testimonial</Title>

                        <Quote>&#8220;{quote}&#8221;</Quote>

                        <Author>
                            <AuthorPic>
                                <GatsbyImage image={author.pic.data} alt={altText} />
                            </AuthorPic>

                            <AuthorDetails>
                                <AuthorName>{author.firstName}&nbsp;{author.lastName}</AuthorName>
                                <AuthorRole>{author.jobTitle}</AuthorRole>
                            </AuthorDetails>
                        </Author>
                    </Content>
                </Column>
            </Row>
        </Jacket>
    );
}

export default CareersTestimonial;