// Imports
// ------------
import React from 'react';
import Icon from '@icons';

// Styles
// ------------
import { Jacket, Heading, HeadingText, LocationPlusType, Salary } from './styles';

// Component
// ------------
const SingleCareer = ({ name, jobType, jobUrl, salary }) => {
    return (
        <Jacket href={jobUrl} target="_blank" rel="noopener noreferrer">
            <Heading>
                <HeadingText>{name}</HeadingText>
                <Icon type="link-out" />
            </Heading>

            <LocationPlusType>{jobType}</LocationPlusType>
            <Salary>{salary}</Salary>
        </Jacket>
    );
}

export default React.memo(SingleCareer);