// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Div, H6, Span } from '@tackl';

// Exports
// ------------
export const Jacket = styled.a(props => css`
    width: 100%;

    border-top: 1px solid ${props.theme.colors.brand.bc5o2};
    padding-top: 3.6rem;
    transition: ${props.theme.easing.ease};

    ${breakup.large`
        padding-top: 6rem;
    `}

    &:hover {
        opacity: 0.6;
    }
`);


export const Heading = styled(Div)(props => css`
    display: flex;
    justify-content: space-between;
    

    svg {
        fill: none;
        stroke: ${props.theme.colors.brand.bc5};
        width: 3.6rem;
        height: 3.6rem;

        ${breakup.large`
            width: 4.8rem;
            height: 4.8rem;
        `}
    }
`);


export const HeadingText = styled(H6)(props => css`
    

`);


export const LocationPlusType = styled(Span)(props => css`
    margin-top: 2.4rem;
`);

export const Salary = styled(Span)(props => css`
    margin-top: 1.2rem;
`);