// Imports
// ------------
import React, { useRef, useEffect } from 'react';
import Button from '@parts/Button';
import gsap from 'gsap';
import { Row, Column } from '@waffl';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Styles
// ------------
import { Jacket, Picture, Parallax, Sticky, StickyTitle, Content, Heading, Text } from './styles';

// Component
// ------------
gsap.registerPlugin(ScrollTrigger);

const CareersAbout = ({ heading, text, image }) => {
    // NOTE • Alt Text Backup
    const altText = image.alt ? image.alt : "Sirio Strategies • About Image";

    // NOTE • Refs
    const trigger = useRef();
    const target = useRef();

    // NOTE • Onload: scroll Animation
    useEffect(() => {
        const timer = setTimeout(() => {
            gsap.to(target.current, {
                y: `-6%`,
                ease: 'none',
                scrollTrigger: {
                    trigger: trigger.current,
                    start: '0% 100%',
                    end: '100% 0%',
                    scrub: 0.5,
                    markers: false,
                },
            });
        }, 250);

        return () => {
            clearTimeout(timer);
        }
    }, []);

    return (
        <Jacket>
            {/* NOTE • Image */}
            <Row isExpanded>
                <Column small={12} large={10} pushLarge={1} mpad>
                    <Picture ref={trigger}>
                        <Parallax ref={target}>
                            <GatsbyImage image={image.data} alt={altText} />
                        </Parallax>
                    </Picture>
                </Column>
            </Row>

            {/* NOTE • Content */}
            <Row isExpanded isEqual>
                {/* NOTE • Sticky Title */}
                <Column small={12} large={3} pushLarge={1} mpad>
                    <Sticky>
                        <StickyTitle>About us</StickyTitle>
                    </Sticky>
                </Column>

                {/* NOTE • Main Content */}
                <Column small={12} large={7} pushLarge={1} mpad>
                    <Content>
                        <Heading>{heading}</Heading>
                        <Text>{text}</Text>
                        <Button icon="jobs" to="#availabilities" label="Availabilties" />
                    </Content>
                </Column>
            </Row>
        </Jacket>
    );
}

export default CareersAbout;