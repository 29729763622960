// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';
import { h2Styles, h3Styles } from '@tackl-type';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: relative;
    z-index: 14;
    background: ${props.theme.colors.brand.bc1};
`);

export const Title = styled.h2(props => css`
    ${h2Styles}
    margin: 0 0 ${props.theme.space.small};

    ${breakup.medium`
        margin: 0 0 ${props.theme.space.medium};
    `}

    ${breakup.large`
        ${h3Styles}
        margin: 0 0 ${props.theme.space.large};
    `}
`);

export const BenefitGrid = styled.div(props => css`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: ${props.theme.space.small};

    ${breakup.large`
        
        grid-template-columns: repeat(2, 1fr);
        grid-gap: ${props.theme.space.large} 3.6rem;
    `}
`);